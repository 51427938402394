import React from 'react'
import { guardAdmin } from '../Admin/admin-base'
import { Helmet } from 'react-helmet';

import './reports.css'

export class Reports extends React.Component {
    render() {
        return guardAdmin(
            <div className="adminMenuPage">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="title">
                    Reports
                </div>
                <div className="body">
                    <div className="systemContentMenu">
                        <ul>
                            <li>
                                <a onClick={() => window.location='/admin/accountingExport'}>
                                    Accounting Export
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/report/importSummaryReport'}>
                                    Import Summary
                                </a>
                            </li>

                            {/* 12/11/24: Per Chris O’Connor, journals functionality removed/hidden until further notice */}
                            {/* <li>
                                <a onClick={() => window.location='/admin/journalSelectionsSnapshot'}>
                                    Journal Selections Snapshot
                                </a>
                            </li> */}

                            <li>
                                <a onClick={() => window.location='/admin/notificationsSnapshot'}>
                                    Notifications Snapshot
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/rblexport'}>
                                    RBL Export
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/subjectSelectionsSnapshot'}>
                                    Subject Selections Snapshot
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
